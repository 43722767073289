<template>
    <div class="md:max-w-screen-xl w-full mx-auto">
        <div class="my-10 flex flex-wrap px-5">
            <div
                class="text-4xl font-normal md:flex-nowrap mb-5 md:mb-0 w-full md:w-auto"
            >
                Lista invitați
            </div>
            <div class="grow"></div>
        </div>

        <table-lite
            v-if="scopedProjectData"
            :has-checkbox="true"
            :is-slot-mode="true"
            :is-static-mode="true"
            :is-loading="loading"
            :columns="columns"
            :rows="scopedProjectData.participants"
            :messages="messages"
            :total="scopedProjectData.participants.length"
            :sortable="sortable"
            :pageOptions="pageOptions"
            :is-hide-paging="true"
            @return-checked-rows="updateCheckedRows"
        >
            <template v-slot:email_sent_time="data">
                <div class="">
                    <div
                        class="tooltip tooltip-left cursor-default"
                        :data-tip="`Invitatie trimisa prin Email la ${data.value.email_sent_time}`"
                        v-if="data.value.email_sent_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-envelope-circle-check"
                            class="text-primary text-2xl pl-1"
                        />
                    </div>

                    <div
                        class="tooltip tooltip-left cursor-default"
                        :data-tip="`Invitatia nu a fost trimisa`"
                        v-else
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-envelope"
                            class="text-neutral text-2xl"
                        />
                    </div>
                </div>
            </template>
        </table-lite>

        <div>
            <div
                v-if="
                    selectedForEmailSend.length == 0 && !isTermsCheckboxChecked
                "
                class="bg-base-200 py-2 rounded-lg text-lg"
            >
                Alege cui vrei sa trimiti invitatia prin Email
            </div>
            <div
                v-else-if="
                    selectedForEmailSend.length == 0 && isTermsCheckboxChecked
                "
                class="bg-accent py-2 rounded-lg text-lg"
            >
                Trebuie sa alegi cel putin un invitat!
            </div>
            <div
                v-else-if="selectedForEmailSend.length > credit.email_credits"
                class="bg-accent py-2 rounded-lg text-lg"
            >
                Nu ai suficiente credite!
            </div>
            <div v-else class="bg-secondary text-white text-lg rounded-lg py-2">
                Urmeaza sa trimiti invitatia catre
                {{ selectedForEmailSend.length }}
                <span v-if="selectedForEmailSend.length > 1">invitați!</span>
                <span v-else>invitat!</span>
            </div>
            <div
                class="flex flex-col md:flex-row items-center justify-between"
                v-if="user"
            >
                <div class="form-control mt-10">
                    <label
                        class="label cursor-pointer flex justify-start text-left"
                    >
                        <input
                            type="checkbox"
                            v-model="isTermsCheckboxChecked"
                            class="checkbox checkbox-primary"
                        />
                        <span class="label-text ml-5"
                            >Am citit și sunt de acord cu
                            <RouterLink
                                to="/termsAndConditions"
                                class="underline italic"
                                >Termenii și condițiile generale</RouterLink
                            >

                            și sunt de acord să trimit invitația.</span
                        >
                    </label>
                </div>

                <button
                    v-if="emailSendingInProgress"
                    class="btn btn-primary btn-wide mt-10"
                    :class="{
                        'btn-disabled':
                            !isTermsCheckboxChecked ||
                            !selectedForEmailSend.length ||
                            emailSendingInProgress,
                    }"
                >
                    <span class="loading loading-spinner"></span>
                    Se trimite
                </button>

                <button
                    @click="sendEmailInvitation()"
                    v-else
                    class="btn btn-primary btn-wide mt-10"
                    :class="{
                        'btn-disabled':
                            !isTermsCheckboxChecked ||
                            !selectedForEmailSend.length ||
                            emailSendingInProgress ||
                            selectedForEmailSend.length >
                                credit.email_credits ||
                            user.user_active != 1 ||
                            plan.subscription_status != 'active',
                    }"
                >
                    <font-awesome-icon icon="fa-solid fa-paper-plane" />
                    Trimite Invitatia
                </button>
            </div>
            <!-- Display failed emails -->
            <div v-if="failedEmails.length > 0" class="divide-y mt-10">
                <div class="divide-y"></div>
                <h3>A aparut o eroare la urmatoarele adrese:</h3>
                <ul>
                    <li v-for="(email, index) in failedEmails" :key="index">
                        {{ email }}
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="loading">
            <div
                class="backdrop fixed w-full h-full bg-white z-50 top-0 right-0 left-0 bottom-0 opacity-90 flex justify-center"
            >
                <span class="loading loading-ring loading-lg"></span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import TableLite from 'vue3-table-lite';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
    props: {
        projectId: {
            required: true,
        },
        closeSendEmailModal: {
            type: Function,
            required: true,
        },
        isSendEmailModalOpen: {
            type: Boolean,
            required: true,
        },
        setEmailJobStatus: {
            type: Function,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'user',
            'projects',
            'plan',
            'credit',
            'loading',
            'scopedProjectData',
        ]),
    },
    watch: {
        isSendEmailModalOpen(newValue) {
            if (newValue) {
                const projectId = this.$route.params.projectId; // Assuming projectId is a URL parameter
                const options = { email: true };
                this.$store.dispatch('fetchProjectUsers', {
                    projectId,
                    options,
                    participants: true,
                });
            }
        },
    },
    components: {
        TableLite,
        RouterLink,
    },
    data() {
        return {
            isTermsCheckboxChecked: false,
            selectedForEmailSend: [],
            failedEmails: [],
            emailSendingInProgress: false,
            pageOptions: [{ value: 999, text: 999 }],
            columns: [
                {
                    label: 'Nume',
                    field: 'recipient_surname',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Prenume',
                    field: 'recipient_name',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Email',
                    field: 'recipient_email',
                    width: '15%',
                    sortable: false,
                    isKey: true,
                },
                {
                    label: 'Status Invitatie',
                    field: 'email_sent_time',
                    width: '5%',
                    sortable: true,
                },
            ],
            sortable: {
                order: 'recipient_name',
                sort: 'desc',
            },
            messages: {
                pagingInfo:
                    'Se afiseaza {0} din {1} rezultate | Total {2} rezultate',
                pageSizeChangeLabel: 'Randuri afisate: ',
                gotoPageLabel: ' Mergi la pagina: ',
                noDataAvailable: 'Nu sunt disponibile informatii',
            },
        };
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    mounted() {},
    methods: {
        updateCheckedRows(rowsKey) {
            this.selectedForEmailSend = rowsKey;
        },
        async sendEmailInvitation() {
            try {
                const jwtToken = localStorage.getItem('userToken');

                this.emailSendingInProgress = true;
                this.setEmailJobStatus(true);
                this.failedEmails = [];

                setTimeout(() => {
                    if (this.emailSendingInProgress == true) {
                        // Close the modal
                        this.closeSendEmailModal();
                    }
                }, 1000);

                await axios
                    .post(
                        `/api/index.php?endpoint=sendEmailInvitation`,
                        {
                            email_list: this.selectedForEmailSend,
                            project_id: this.projectId,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        this.emailSendingInProgress = false;
                        if (response.data.success) {
                            this.toast.success(
                                'Succes! Invitatia se trimite chiar acum.'
                            );
                            this.closeSendEmailModal();
                        } else {
                            this.toast.error('A aparut o eroare.');

                            // Add failed emails to the failedEmails array
                            if (response.data.email_responses) {
                                response.data.email_responses.forEach(
                                    (emailResponse) => {
                                        if (!emailResponse.success) {
                                            this.failedEmails.push(
                                                emailResponse.email
                                            );
                                        }
                                    }
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        this.toast.error(
                            'Invitatia nu s-a trimis catre: ' + error
                        );
                    });
                this.setEmailJobStatus(false);
                this.$store.commit('setLoading', false);
                const burnedCredits =
                    this.selectedForEmailSend.length - this.failedEmails.length;

                // Calculate the new value for email_credits
                const newEmailCredits =
                    this.$store.state.credit.email_credits - burnedCredits;

                this.$store.commit('setEmailCredits', newEmailCredits);
            } catch (error) {
                console.error(
                    'A aparut o eroare:',
                    error.response ? error.response.data : error.message
                );
                this.$store.commit('setLoading', false);
                this.toast.error('A aparut o eroare.');
            }
        },
    },
};
</script>

<style>
/* Add any styling specific to this component */

.participantsList table {
    border-radius: 20px;
    border: 1px solid #cecece;
}

/* .vtl-table .vtl-thead .vtl-thead-th {
    color: #fff;
    background-color: #42b983;
    border-color: #42b983;
} */
</style>
