<template>
    <div v-if="editMode">
        <div class="tooltip" data-tip="Modifica Dimensiune Text">
            <button
                class="btn btn-xs md:btn-sm btn-square"
                @click="openModal"
                :class="isEditing ? 'btn-accent' : ''"
            >
                <font-awesome-icon
                    icon="fa-solid fa-up-right-and-down-left-from-center"
                />
            </button>
        </div>

        <ModalComponent :is-open="isModalOpen" @close="closeModal">
            <h3 class="font-bold text-lg">Modifica Dimensiune Text</h3>
            <button
                @click="closeModal"
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
                ✕
            </button>
            <div class="py-4">
                <div>
                    <select
                        class="select select-bordered w-full max-w-xs"
                        v-model="editedValue"
                        @change="updateValue"
                    >
                        <option
                            v-for="(size, index) in sizes"
                            :key="index"
                            :value="size.value"
                        >
                            {{ size.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-action">
                <button class="btn btn-success" @click="saveEdit">
                    Salveaza
                </button>
                <button class="btn" @click="closeModal">Anuleaza</button>
            </div>
        </ModalComponent>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    props: {
        value: String,
        section: Object,
        sectionKey: String,
        propertyKey: String,
        config: Object,
    },
    components: {
        ModalComponent,
    },
    data() {
        // TODO: Change this value system to add classes instead, desktop and mobile
        return {
            isEditing: false,
            editedValue: this.value,
            sizes: [
                { label: 'XS', value: 10 },
                { label: 'S', value: 20 },
                { label: 'M', value: 40 },
                { label: 'L', value: 70 },
                { label: 'XL', value: 100 },
                { label: 'XXL', value: 140 },
            ],
            isModalOpen: false,
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        toggleEditMode() {
            this.isEditing = !this.isEditing;
            if (this.isEditing) {
                this.editedValue = this.value;
            }
        },
        updateValue(event) {
            this.editedValue = event.target.value;
            this.saveEdit();
        },
        saveEdit() {
            this.closeModal();
            this.isEditing = false;
            this.updateNestedValue(
                this.section,
                this.propertyKey,
                this.editedValue
            );
            this.$emit('update-section', this.sectionKey, this.section);
        },
        updateNestedValue(obj, path, value) {
            const keys = path.split('.');
            let o = obj;
            for (let i = 0; i < keys.length - 1; i++) {
                o = o[keys[i]];
            }
            o[keys[keys.length - 1]] = value;
        },
    },
};
</script>

<style scoped>
.icon-edit {
    cursor: pointer;
}
select {
    margin-top: 10px;
}
</style>
