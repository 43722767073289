<template>
    <div v-if="scopedProjectData">
        <div class="mb-10">
            <div class="mb-4">Din ce proiect vrei sa copiezi invitați?</div>
            <select
                class="select select-bordered w-full max-w-xs"
                v-model="selectedProject"
                @change="handleProjectSelect"
            >
                <option disabled value="0">Alege un proiect</option>
                <option
                    v-for="project in projects"
                    :key="project.project_id"
                    :value="project.project_id"
                    :disabled="project.project_id == projectId"
                >
                    {{ project.project_name }}
                    <span v-if="project.project_id == projectId"
                        >- Proiectul actual</span
                    >
                </option>
            </select>
        </div>
        <div v-if="selectedParticipants.length > 0">
            <table-lite
                v-if="selectedParticipants"
                :has-checkbox="true"
                :is-slot-mode="true"
                :is-static-mode="true"
                :is-loading="loading"
                :columns="columns"
                :rows="selectedParticipants"
                :messages="messages"
                :total="selectedParticipants.length"
                :sortable="sortable"
                :pageOptions="pageOptions"
                :is-hide-paging="true"
                @return-checked-rows="updateCheckedRows"
            >
                <template v-slot:sms_sent_time="data">
                    <div class="">
                        <div
                            class="tooltip tooltip-left cursor-default"
                            :data-tip="`Invitatie trimisa prin SMS la ${data.value.sms_sent_time}`"
                            v-if="data.value.sms_sent_time"
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-envelope-circle-check"
                                class="text-primary text-2xl pl-1"
                            />
                        </div>

                        <div
                            class="tooltip tooltip-left cursor-default"
                            :data-tip="`Invitatia nu a fost trimisa`"
                            v-else
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-envelope"
                                class="text-neutral text-2xl"
                            />
                        </div>
                    </div>
                </template>
            </table-lite>
        </div>
        <div class="flex justify-center align-middle">
            <button class="btn mr-5" @click.prevent="closeMigrateUserModal()">
                Anuleaza
            </button>

            <button
                @click.prevent="copySelectedParticipants()"
                class="btn btn-primary"
                :class="{
                    'btn-disabled': selectedForCopy.length == 0 || loading,
                }"
            >
                <font-awesome-icon icon="fa-solid fa-copy" />
                Copiaza
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import TableLite from 'vue3-table-lite';
import { useToast } from 'vue-toastification';
import { handleDeauth } from '@/utils/auth';
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            selectedProject: '0',
            isTermsCheckboxChecked: false,
            selectedForCopy: [],
            pageOptions: [{ value: 999, text: 999 }],
            selectedParticipants: [],
            columns: [
                {
                    label: 'Nume',
                    field: 'recipient_surname',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Prenume',
                    field: 'recipient_name',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'ID',
                    field: 'invitation_id',
                    width: '10%',
                    isKey: true,
                },
            ],
            sortable: {
                order: 'recipient_name',
                sort: 'desc',
            },
            messages: {
                pagingInfo:
                    'Se afiseaza {0} din {1} rezultate | Total {2} rezultate',
                pageSizeChangeLabel: 'Randuri afisate: ',
                gotoPageLabel: ' Mergi la pagina: ',
                noDataAvailable: 'Nu sunt disponibile informatii',
            },
        };
    },
    props: {
        projectId: {
            required: true,
        },
        closeModal: {
            type: Function,
            required: true,
        },
    },
    components: {
        TableLite,
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    created() {
        // Check if the user is already authenticated (e.g., on page reload)
        this.isAuthenticated = this.checkAuthentication();
        // this.fetchProjects();
    },
    computed: {
        ...mapGetters([
            'user',
            'projects',
            'plan',
            'credit',
            'loading',
            'scopedProjectData',
        ]),
    },
    mounted() {
        if (!this.projects) {
            this.fetchUserDetails();
        }
    },
    methods: {
        ...mapActions(['fetchUserDetails']),
        updateCheckedRows(rowsKey) {
            this.selectedForCopy = rowsKey;
        },
        closeMigrateUserModal() {
            this.closeModal(); // Notify the parent to close the modal
        },

        checkAuthentication() {
            // Check if the authentication token is present in localStorage
            const token = localStorage.getItem('userToken');
            return !!token; // Returns true if the token is present, otherwise false
        },
        async fetchProjects_deprecated() {
            try {
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');

                await axios
                    .get(`/api/index.php?endpoint=getUserData`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    })
                    .then((response) => {
                        if (response.data.message == 'Token validation error') {
                            handleDeauth(this);
                        }

                        this.user = response.data.data;
                        this.projects = this.user.projects;
                        this.$store.commit('setLoading', false);
                    })
                    .catch((error) => {
                        console.log(
                            `An error occured while fetching projects, ${error}`
                        );
                    });
            } catch (error) {
                console.error(
                    'Error fetching user details:',
                    error.response ? error.response.data : error.message
                );
                this.$store.commit('setLoading', false);
            }
        },
        async handleProjectSelect() {
            try {
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');
                const selectedProject = this.selectedProject;

                const response = await axios.get(
                    `/api/index.php?endpoint=getUserProject&projectID=${selectedProject}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                // Set the user data
                this.selectedParticipants =
                    response.data.data.projects[0].participants;

                this.$store.commit('setLoading', false);
            } catch (error) {
                console.error(
                    'Error fetching user details:',
                    error.response ? error.response.data : error.message
                );
                this.$store.commit('setLoading', false);
            }
        },
        async copySelectedParticipants() {
            try {
                const jwtToken = localStorage.getItem('userToken');
                this.$store.commit('setLoading', true);
                this.copyInProgress = true;

                await axios
                    .post(
                        `/api/index.php?endpoint=copyParticipants`,
                        {
                            copy_list: this.selectedForCopy,
                            source_project_id: this.selectedProject,
                            project_id: this.projectId,
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        this.copyInProgress = false;
                        if (response.data.success) {
                            this.toast.success(
                                'Succes! Invitatii se copiaza chiar acum.'
                            );
                            this.$store.commit('setLoading', false);
                            this.closeMigrateUserModal();

                            const projectId = this.$route.params.projectId; // Assuming projectId is a URL parameter
                            const options = {};
                            this.$store.dispatch('fetchProjectUsers', {
                                projectId,
                                options,
                                participants: true,
                            });
                        } else {
                            if (response.data.maxParticipantCountReached) {
                                this.toast.error(
                                    'Actiunea depaseste numarul maxim de participanti'
                                );
                            } else {
                                this.toast.error('A aparut o eroare.');
                            }
                        }
                    })
                    .catch((error) => {
                        this.toast.error('A aparut o eroare: ' + error);
                    });
                this.$store.commit('setLoading', false);
            } catch (error) {
                this.$store.commit('setLoading', false);
                this.toast.error(`A aparut o eroare. ${error}`);
            }
        },
    },
};
</script>

<style scoped>
/* No need to add additional styles here for Tailwind CSS */
</style>
