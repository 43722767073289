<template>
    <div>
        <div v-if="!isAuthenticated">
            <router-link to="/register">
                <button
                    class="font-medium text-xs md:text-sm tracking-wide py-2 px-5 border border-orange-500 text-orange-500 bg-white-500 outline-none rounded-l-full rounded-r-full capitalize hover:bg-orange-500 hover:text-white transition-all hover:shadow-orange"
                >
                    Inregistrare
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAuthenticated: false,
        };
    },
    created() {
        // Check if the user is already authenticated (e.g., on page reload)
        this.isAuthenticated = this.checkAuthentication();

        // You may want to add a global event listener to listen for authentication changes
        window.addEventListener('storage', this.handleStorageEvent);
    },
    methods: {
        checkAuthentication() {
            // Check if the authentication token is present in localStorage
            const token = localStorage.getItem('userToken');
            return !!token; // Returns true if the token is present, otherwise false
        },

        handleStorageEvent(event) {
            // Listen for changes in localStorage (e.g., logout in another tab)
            if (event.key === 'userToken') {
                this.isAuthenticated = this.checkAuthentication();
            }
        },
    },
};
</script>

<style scoped>
/* No need to add additional styles here for Tailwind CSS */
</style>
