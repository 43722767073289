<template>
    <div v-if="editMode">
        <div class="tooltip tooltip-right" data-tip="Imagine Fundal">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg"
                @click="editBackgroundImage"
            >
                <font-awesome-icon icon="fa-solid fa-image " />
            </button>
        </div>

        <div v-if="isEditing">
            <label>{{ config._displayName }}</label>
            <input
                type="image"
                :value="value"
                :src="value"
                class="h-12 mr-2 border rounded-md px-5 py-2 w-full"
                @click="editBackgroundImage"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        value: String,
        config: Object,
    },
    data() {
        return {
            isEditing: false,
            editedValue: this.value,
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        editBackgroundImage() {
            this.$emit('edit-background-image');
        },
    },
};
</script>

<style scoped></style>
