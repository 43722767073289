<template>
    <div class="md:max-w-screen-xl w-full mx-auto">
        <h2 class="my-10 text-2xl font-normal">Raspunsuri formular</h2>

        <table-lite
            v-if="responses"
            :is-slot-mode="true"
            :is-static-mode="true"
            :is-loading="loading"
            :columns="columns"
            :rows="responses"
            :messages="messages"
            :total="responses.length"
            :sortable="sortable"
            :pageOptions="pageOptions"
        >
            <template v-slot:attendance_status="data">
                <div v-if="data.value.response_adults" class="indicator">
                    <span
                        v-if="data.value.response_menu_type == 'vegetarian'"
                        class="indicator-item badge badge-primary shadow-md"
                    >
                        <font-awesome-icon icon="fa-solid fa-leaf" />
                    </span>

                    <div class="join">
                        <div
                            class="join-item badge badge-primary badge-lg"
                            v-if="data.value.attendance_status == 'Attending'"
                        >
                            <font-awesome-icon icon="fa-solid fa-user-check" />
                            <span class="pl-2">Participa</span>
                        </div>
                        <div
                            class="join-item badge bg-blue-500 text-white badge-lg"
                            v-if="data.value.attendance_status == 'Attending'"
                        >
                            <div class="tooltip" data-tip="Numar Adulti">
                                <span
                                    ><font-awesome-icon
                                        class=""
                                        icon="fa-solid fa-users"
                                    />{{ data.value.response_adults }}</span
                                >
                            </div>
                        </div>

                        <div
                            class="join-item badge bg-blue-600 text-white badge-lg"
                            v-if="data.value.response_children"
                        >
                            <div class="tooltip" data-tip="Numar Copii">
                                <span v-if="data.value.response_children"
                                    ><font-awesome-icon
                                        class=""
                                        icon="fa-solid fa-children"
                                    />{{ data.value.response_children }}</span
                                >
                            </div>
                        </div>
                        <div
                            class="join-item badge bg-blue-700 text-white badge-lg"
                            v-if="data.value.response_accommodation"
                        >
                            <div class="tooltip" data-tip="Cazare">
                                <font-awesome-icon icon="fa-solid fa-bed" />
                            </div>
                        </div>

                        <div
                            class="join-item badge bg-blue-800 text-white badge-lg"
                            v-if="data.value.response_children_menu"
                        >
                            <div class="tooltip" data-tip="Meniu Copii">
                                <font-awesome-icon
                                    icon="fa-solid fa-candy-cane"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="badge badge-accent badge-lg"
                    v-if="data.value.attendance_status == 'Not Attending'"
                >
                    <font-awesome-icon icon="fa-solid fa-user-slash" />
                    <span class="pl-2">Nu Participa</span>
                </div>
            </template>

            <template v-slot:email_sent_time="data">
                <div class="grid grid-cols-3">
                    <div
                        class="tooltip cursor-default pl-1"
                        :data-tip="`Invitatie trimisa prin Email la ${data.value.email_sent_time}`"
                        v-if="data.value.email_sent_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-envelope-circle-check"
                            class="text-primary text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default"
                        :data-tip="`Invitatia nu a fost trimisa`"
                        v-else
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-envelope"
                            class="text-base-300 text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default pl-1"
                        :data-tip="`Invitatie trimisa prin SMS la ${data.value.sms_sent_time}`"
                        v-if="data.value.sms_sent_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-mobile-screen"
                            class="text-primary text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default"
                        :data-tip="`Invitatia nu a fost trimisa`"
                        v-else
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-mobile-screen"
                            class="text-base-300 text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default"
                        :data-tip="`Invitatie vizualizata la ${data.value.opened_time}`"
                        v-if="data.value.opened_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-eye"
                            class="text-primary text-2xl"
                        />
                    </div>
                    <div v-else>
                        <font-awesome-icon
                            icon="fa-solid fa-eye-slash"
                            class="text-base-300 text-2xl"
                        />
                    </div>
                </div>
            </template>

            <template v-slot:invitation_id="data">
                <button
                    @click="openDeleteModal(data.value)"
                    class="btn btn-sm btn-square hover:btn-accent tooltip"
                    data-tip="Sterge"
                >
                    <font-awesome-icon icon="fa-solid fa-user-xmark" />
                </button>
            </template>
        </table-lite>
        <p v-if="responses.length === 0" class="text-gray-500 mt-4">
            Momentan nu sunt date disponibile.
        </p>

        <ModalComponent :is-open="isDeleteModalOpen" @close="closeDeleteModal">
            <!-- Content for Modal 1 -->

            <h3 class="font-bold text-lg">Sterge Invitat</h3>
            <p class="py-4"></p>
            <p>
                Urmeaza sa stergi raspunsul invitatului
                <span class="font-bold"
                    >{{ selectedDeleteResponse.response_name }}
                    {{ selectedDeleteResponse.response_surname }}</span
                >.
            </p>
            <p>Aceasta actiune este ireversibila.</p>
            <div class="modal-action justify-center">
                <form method="dialog">
                    <button
                        @click="closeDeleteModal"
                        class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    >
                        ✕
                    </button>

                    <button class="btn mr-5" @click="closeDeleteModal">
                        Anuleaza
                    </button>

                    <button
                        @click="
                            deleteResponse(selectedDeleteResponse.response_id)
                        "
                        class="btn btn-accent"
                    >
                        Am inteles, sterge invitatul
                    </button>
                </form>
            </div>
        </ModalComponent>

        <div v-if="loading">
            <LoadingComponent></LoadingComponent>
        </div>
    </div>
</template>

<script>
import TableLite from 'vue3-table-lite';
import LoadingComponent from './LoadingComponent.vue';
import ModalComponent from '@/components/ModalComponent.vue';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    props: ['projectId'],
    components: { TableLite, LoadingComponent, ModalComponent },
    data() {
        return {
            isDeleteModalOpen: false,
            selectedDeleteResponse: {},
            isEditModalOpen: false,
            editedParticipant: {},
            selectedProject: null,
            responses: [],
            pageOptions: [
                { value: 100, text: 100 },
                { value: 150, text: 150 },
                { value: 200, text: 200 },
            ],
            columns: [
                {
                    label: 'Nume',
                    field: 'response_surname',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Prenume',
                    field: 'response_name',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Status Participare',
                    field: 'attendance_status',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Mesaj',
                    field: 'response_message',
                    width: '15%',
                    sortable: true,
                },
                {
                    label: 'Actiuni',
                    field: 'invitation_id',
                    width: '5%',
                    sortable: false,
                },
            ],
            sortable: {
                order: 'recipient_name',
                sort: 'desc',
            },
            messages: {
                pagingInfo:
                    'Se afiseaza {0} din {1} rezultate | Total {2} rezultate',
                pageSizeChangeLabel: 'Numar randuri afisate: ',
                gotoPageLabel: ' Mergi la pagina: ',
                noDataAvailable: 'Nu sunt disponibile informatii',
            },
        };
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    mounted() {
        this.getSimpleResponses();
    },
    computed: {
        ...mapGetters([
            'projects',
            'plan',
            'loading',
            'scopedProjectData',
            'stats',
        ]),
    },
    methods: {
        openDeleteModal(participant) {
            this.isDeleteModalOpen = true;
            this.selectedDeleteResponse = participant;
        },
        closeDeleteModal() {
            this.isDeleteModalOpen = false;
        },
        async deleteResponse(responseId) {
            this.closeDeleteModal();
            const projectId = this.projectId;

            try {
                const deleteResponse = await this.$store.dispatch(
                    'deleteResponse',
                    {
                        responseId,
                        projectId,
                    }
                );

                if (deleteResponse && deleteResponse.success == true) {
                    this.toast.success(
                        `Intrarea a fost stersa din lista de raspunsuri!`
                    );

                    // Remove the entry from the participants array
                    // const index = this.responses.findIndex(
                    //     (response) =>
                    //         response.response_id === deleteResponse.responseId
                    // );

                    // if (index !== -1) {
                    //     this.responses.splice(index, 1);
                    // }

                    // Reload the page
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            } catch (error) {
                this.toast.error(`Intrarea nu a putut fi stearsa`);
            }
        },
        async getSimpleResponses() {
            try {
                const jwtToken = localStorage.getItem('userToken');
                this.$store.commit('setLoading', true);

                await axios
                    .get(
                        `/api/index.php?endpoint=getSimpleResponses&projectID=${this.projectId}`,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.success) {
                            this.responses = response.data.responses;
                        }

                        this.$store.dispatch('setStatsSimple', this.responses);
                        this.$store.commit('setLoading', false);
                    });
            } catch (error) {
                this.$store.commit('setLoading', false);
                console.error(
                    'A aparut o eroare:',
                    error.response ? error.response.data : error.message
                );
            }
        },
    },
};
</script>

<style>
/* Add any styling specific to this component */

.participantsList table {
    border-radius: 20px;
    border: 1px solid #cecece;
}
</style>
