<template>
    <div v-if="editMode">
        <ModalComponent
            :is-open="isLocationElemetsModalOpen"
            @close="closeLocationElementsModal"
        >
            <h3 class="font-bold text-lg">Adauga elemente locatie</h3>
            <div v-if="section.locationElements" class="w-full my-4">
                <div>
                    <button
                        class="btn btn-accent"
                        @click="deleteLocationElements"
                    >
                        Sterge elemente locatie
                    </button>
                </div>
                <div
                    v-for="(location, locationKey) in section.locationElements"
                    :key="locationKey"
                    class="max-w-lg"
                >
                    <div
                        class="flex flex-wrap my-2 py-2 -mx-5 px-5 transition-height bg-neutral-content overflow-hidden"
                        :class="{
                            'h-14':
                                location.visible === 'false' ||
                                location.visible === false,
                            'h-80':
                                location.visible === 'true' ||
                                location.visible === true,
                        }"
                    >
                        <div class="w-full flex justify-between items-center">
                            <div class="text-lg font-bold">
                                {{ location.locationTitle }}
                            </div>
                            <div>
                                <div class="form-control">
                                    <label class="label cursor-pointer">
                                        <span class="label-text mr-2"
                                            >Ascunde</span
                                        >
                                        <input
                                            type="checkbox"
                                            class="toggle"
                                            v-model="location.visible"
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="w-1/2 pr-2">
                            <label class="form-control w-full max-w-xs">
                                <div class="label">
                                    <span class="label-text">Imagine</span>
                                </div>
                                <div
                                    class="h-12 px-5 py-2 border hover:opacity-35 transition bg-cover bg-center cursor-pointer rounded-md shadow-md"
                                    @click="editImage(locationKey)"
                                    :style="{
                                        backgroundImage: `url('${location.image}')`,
                                    }"
                                ></div>
                            </label>
                        </div>
                        <div class="w-1/2">
                            <label class="form-control w-full max-w-xs">
                                <div class="label">
                                    <span class="label-text">Tip Locatie</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="location.locationTitle"
                                    class="h-12 mr-2 border rounded-md px-5 py-2 w-full"
                                />
                            </label>
                        </div>
                        <div class="w-1/2 pr-2">
                            <label class="form-control w-full max-w-xs">
                                <div class="label">
                                    <span class="label-text">Data</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="location.date"
                                    class="h-12 mr-2 border rounded-md px-5 py-2 w-full"
                                />
                            </label>
                        </div>
                        <div class="w-1/2">
                            <label class="form-control w-full max-w-xs">
                                <div class="label">
                                    <span class="label-text">Nume Locatie</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="location.locationName"
                                    class="h-12 mr-2 border rounded-md px-5 py-2 w-full"
                                />
                            </label>
                        </div>
                        <div class="w-1/2">
                            <label class="form-control w-full">
                                <div class="label">
                                    <span class="label-text"
                                        >Adresa Locatie</span
                                    >
                                </div>
                                <input
                                    type="text"
                                    v-model="location.locationAddress"
                                    class="h-12 border rounded-md px-5 py-2 w-full"
                                />
                            </label>
                        </div>
                        <div class="w-1/2">
                            <label class="form-control w-full">
                                <div class="label">
                                    <span class="label-text">Coordonate</span>
                                </div>
                                <input
                                    type="text"
                                    v-model="location.locationCoordonates"
                                    class="h-12 border rounded-md px-5 py-2 w-full"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <button class="btn" @click="addLocationElements">
                    Activeaza elemente locatie
                </button>
            </div>
            <div class="modal-action">
                <form method="dialog">
                    <button
                        @click="closeLocationElementsModal"
                        class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    >
                        ✕
                    </button>
                </form>
            </div>
        </ModalComponent>

        <div class="tooltip tooltip-right" data-tip="Elemente Locatie">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg"
                v-if="
                    !section.locationElements ||
                    section.locationElements === false
                "
                @click="openLocationElementsModal"
            >
                <font-awesome-icon icon="fa-solid fa-map-location-dot" />
            </button>

            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg btn-accent"
                v-else
                @click="openLocationElementsModal"
            >
                <font-awesome-icon icon="fa-solid fa-map-location-dot" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    props: {
        section: {
            type: Object,
            required: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
    },
    components: {
        ModalComponent,
    },
    data() {
        return {
            isEditing: false,
            editedValue: this.value,
            isLocationElemetsModalOpen: false,
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        openLocationElementsModal() {
            this.isLocationElemetsModalOpen = true;
        },
        closeLocationElementsModal() {
            this.isLocationElemetsModalOpen = false;
        },
        editImage(locationKey) {
            this.$emit(
                'edit-image',
                this.sectionKey,
                'locationElements',
                'image',
                locationKey
            );
        },
        deleteLocationElements() {
            const updatedSection = { ...this.section };
            delete updatedSection.locationElements;
            this.$emit('update-section', this.sectionKey, updatedSection);
        },
        addLocationElements() {
            const locationElements = {
                location1: {
                    visible: true,
                    image: 'project-images/wedding-stock-2.jpg',
                    date: 'Data',
                    locationTitle: 'Cununia Civilă',
                    locationName: 'Numele locatiei',
                    locationAddress: 'Adresa locatiei',
                },
                location2: {
                    visible: true,
                    image: 'project-images/wedding-stock-4.jpg',
                    date: 'Data2',
                    locationTitle: 'Cununia Religioasă',
                    locationName: 'Numele locatiei',
                    locationAddress: 'Adresa locatiei',
                },
                location3: {
                    visible: true,
                    image: 'project-images/wedding-stock-3.jpg',
                    date: 'Data3',
                    locationTitle: 'Petrecerea',
                    locationName: 'Numele locatiei',
                    locationAddress: 'Adresa locatiei',
                },
            };
            this.$emit('update-section', this.sectionKey, {
                ...this.section,
                locationElements,
            });
            this.openLocationElementsModal();
        },
    },
};
</script>

<style scoped></style>
