import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { checkAuthentication } from '../utils/auth'; // Import checkAuthentication
import ProjectDetails from '../views/ProjectDetails.vue';
import PublicInvitation from '../views/PublicInvitation.vue';
import ProjectDetailsInvitations from '../views/ProjectDetailsInvitations.vue';
import ProjectDetailsStats from '../views/ProjectDetailsStats.vue';
import ProjectDetailsConfig from '../views/ProjectDetailsConfig.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/howto',
        name: 'How To',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/HowTo.vue'),
    },
    {
        path: '/cart',
        name: 'cart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CartView.vue'),
    },
    {
        path: '/pachete',
        name: 'pachete',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ServicesView.vue'),
    },

    {
        path: '/contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ContactForm.vue'),
    },
    {
        path: '/checkoutDone',
        name: 'Checkout Done',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CheckoutDone.vue'),
    },
    {
        path: '/checkoutError',
        name: 'Checkout Error',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/CheckoutError.vue'
            ),
    },
    {
        path: '/checkout',
        name: 'checkout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CheckoutView.vue'),
    },
    {
        path: '/public/:projectId/:invitationId/:projectSlug',
        name: 'PublicInvitationName',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
        }),
    },
    {
        path: '/public/:projectId/:invitationId',
        name: 'PublicInvitation',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
        }),
    },
    {
        path: '/ps/:projectId/:projectSlug',
        name: 'PublicInvitationSimpleName',
        component: PublicInvitation,
        props: () => ({
            isSimple: true,
        }),
    },
    {
        path: '/ps/:projectId',
        name: 'PublicInvitationSimple',
        component: PublicInvitation,
        props: () => ({
            isSimple: true,
        }),
    },

    {
        path: '/admin',
        name: 'Admin Dashboard',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/CustomersView.vue'
            ),
    },

    {
        path: '/admin/credits',
        name: 'Admin Credits',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/CreditsView.vue'
            ),
    },

    {
        path: '/admin/customers',
        name: 'Admin Customers',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/CustomersView.vue'
            ),
    },

    {
        path: '/admin/settings',
        name: 'Admin Settings',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/admin/SettingsView.vue'
            ),
    },
    {
        path: '/userConfig',
        name: 'userConfig',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/UserConfig.vue'),
    },
    {
        path: '/UserConfigSettings',
        name: 'userConfigSettings',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/UserConfigSettings.vue'
            ),
    },
    {
        path: '/projects',
        name: 'projects',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    },
    {
        path: '/resetPassword',
        name: 'Reset Password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/ResetPassword.vue'
            ),
    },
    {
        path: '/forgotPassword',
        name: 'Forgot Password',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/ForgotPassword.vue'
            ),
    },
    {
        path: '/register',
        name: 'register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next('/');
            } else {
                next(); // Allow access
            }
        },
    },
    {
        path: '/termsAndConditions',
        name: 'Termeni si conditii de utilizare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/TermsAndConditions.vue'
            ),
    },
    {
        path: '/privacyPolicy',
        name: 'Politica de Confidentialitate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue'
            ),
    },
    {
        path: '/cookiePolicy',
        name: 'Politica de Cookies',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/CookiePolicy.vue'),
    },
    {
        path: '/project/:projectId/:projectSlug',
        name: 'ProjectDetails',
        component: ProjectDetails,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectSlug/invitati',
        name: 'ProjectDetailsInvitations',
        component: ProjectDetailsInvitations,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectSlug/config',
        name: 'ProjectDetailsConfig',
        component: ProjectDetailsConfig,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectSlug/statistici',
        name: 'ProjectDetailsStats',
        component: ProjectDetailsStats,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/addProject',
        name: 'addProject',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AddProject.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

export default router;
