<template>
    <div>
        <!-- Button to open the modal -->
        <div>Trimite un Email de test</div>
        <button
            @click="openSendEmailModal"
            class="btn btn-primary mt-2"
            :class="{ 'btn-disabled': isDisabled }"
        >
            <font-awesome-icon icon="fa-solid fa-envelope" class="" />
            Testeaza Email
        </button>

        <!-- Modal -->
        <div v-if="isModalOpen" class="modal modal-open">
            <div class="modal-box">
                <h2 class="font-bold text-lg">Trimite un Email de test</h2>
                <p class="py-4">
                    Această funcționalitate permite testarea trimiterii unui
                    Email.
                    <b>Creditele Email vor fi utilizate pentru trimitere</b>.
                </p>

                <div class="form-control">
                    <label class="label">
                        <span class="label-text">Adresa de Email</span>
                    </label>
                    <input
                        type="email"
                        v-model="email"
                        placeholder="Introdu numărul de telefon"
                        class="input input-bordered"
                    />
                </div>

                <!-- Terms and Conditions Checkbox -->
                <div class="form-control mb-4 mt-2">
                    <label class="cursor-pointer flex items-center">
                        <input
                            type="checkbox"
                            class="checkbox checkbox-primary"
                            v-model="termsAccepted"
                        />
                        <span class="label-text ml-3 text-left">
                            Sunt de acord cu
                            <RouterLink
                                to="termsAndConditions"
                                class="link link-primary"
                                target="_blank"
                                >Termenii și Condițiile de
                                utilizare.</RouterLink
                            >
                        </span>
                    </label>
                </div>

                <div class="modal-action">
                    <button
                        @click="sendTestEmail"
                        class="btn btn-success"
                        :disabled="!termsAccepted"
                        :class="
                            loading
                                ? 'btn-disabled loading loading-spinner'
                                : ''
                        "
                    >
                        Trimite
                    </button>
                    <button
                        @click="closeSendEmailModal"
                        class="btn"
                        :class="loading ? 'btn-disabled' : ''"
                    >
                        Închide
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
    props: {
        userEmail: {
            type: String,
            required: true,
        },
        projectID: {
            type: Number,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            isModalOpen: false,
            email: this.userEmail,
            loading: false,
            termsAccepted: false,
        };
    },
    methods: {
        openSendEmailModal() {
            this.isModalOpen = true;
        },
        closeSendEmailModal() {
            this.isModalOpen = false;
        },
        async sendTestEmail() {
            this.loading = true;

            try {
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.post(
                    '/api/index.php?endpoint=sendEmailInvitation',
                    {
                        is_test_email: true,
                        email: this.email,
                        project_id: this.projectID,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.success) {
                    this.toast.success('Email trimis cu succes!');
                    this.closeSendEmailModal();
                    this.loading = false;
                } else {
                    this.toast.error(
                        response.data.message ||
                            'Eroare la trimiterea Email-ului.'
                    );
                    this.loading = false;
                }
            } catch (error) {
                this.toast.error(
                    'A apărut o eroare la comunicarea cu serverul.' + error
                );
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
