<template>
    <div
        class="border p-10 shadow-lg rounded-2xl max-w-96"
        :class="{
            'bg-gradient-to-b from-emerald-500 to-emerald-700':
                isEmailScope == false,
            'bg-gradient-to-b  from-cyan-600 to-cyan-800': isEmailScope == true,
        }"
    >
        <div
            v-if="isEmailScope"
            class="text-4xl mb-2 font-extralight text-white"
        >
            Mesaj invitatie Email
        </div>
        <div v-else class="text-4xl mb-2 font-extralight text-white">
            Mesaj invitatie SMS
        </div>

        <div v-if="!hideForm">
            <label class="form-control">
                <div class="label">
                    <span class="label-text text-white"
                        >Personalizeaza mesajul</span
                    >
                </div>
                <textarea
                    v-model="userProjectMessage"
                    @keyup="handleCharCount"
                    class="textarea textarea-bordered h-24"
                    :placeholder="demoProjectMessage"
                ></textarea>
            </label>
        </div>

        <div v-if="isEmailScope">
            <div class="mockup-browser bg-base-300 border mt-6">
                <div class="mockup-browser-toolbar">
                    <div class="input"></div>
                </div>
                <div class="bg-base-200 px-4 text-xs">
                    <div>...</div>
                    {{
                        userProjectMessage
                            ? userProjectMessage
                            : demoProjectMessage
                    }}
                    <a href="#" class="underline break-all">{{
                        demoMessageURL
                    }}</a>
                    <div>...</div>
                </div>
            </div>
            <div
                v-if="!hideForm"
                class="badge badge-md"
                :class="{
                    'badge-error': messageCharCount <= 0,
                    'badge-warning': messageCharCount < 10,
                    'badge-primary': messageCharCount > 0,
                }"
            >
                Caractere ramase:
                {{ messageCharCount >= 0 ? messageCharCount : 0 }}
            </div>
        </div>

        <div v-else class="chat chat-start mt-6 relative -ml-3 -mr-8">
            <div class="chat-bubble break-all">
                {{
                    userProjectMessage ? userProjectMessage : demoProjectMessage
                }}
                <a href="#" class="underline">{{ demoMessageURL }}</a>
            </div>
            <div
                v-if="!hideForm"
                class="badge badge-md absolute right-10 -bottom-5"
                :class="{
                    'badge-error': messageCharCount <= 0,
                    'badge-warning': messageCharCount < 10,
                    'badge-primary': messageCharCount > 0,
                }"
            >
                Caractere ramase:
                {{ messageCharCount >= 0 ? messageCharCount : 0 }}
            </div>
        </div>

        <button
            v-if="!hideForm"
            class="btn btn-wide mt-12"
            @click="saveProjectMessage()"
        >
            Salveaza
        </button>
    </div>
</template>

<script>
import axios from 'axios';
// import eventBus from '@/utils/EventBus';
import { useToast } from 'vue-toastification';

export default {
    name: 'InvitationMessage',
    components: {},
    props: {
        projectId: {
            required: true,
        },
        projectName: {
            required: true,
        },
        projectSlug: {
            required: true,
        },
        hideForm: {
            type: Boolean,
        },
        userMessage: {
            type: String,
            required: true,
        },
        nameInURL: {
            type: Number,
            default: 0,
        },
        template_type: {
            type: String,
            default: 'simple',
        },
        isEmailScope: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentProjectId: null,
            messageCharCountLimit: this.isEmailScope ? 300 : 150, // Double limit if isEmailScope is true
            messageCharCount: this.isEmailScope ? 300 : 150,
            userProjectMessage: '',
            demoProjectMessage:
                'Salutare! Ai primit o invitatie digitala. O poti accesa aici:',
            demoMessageURL: this.generateDemoMessageURL(),
        };
    },
    created() {
        this.handleCharCount();
        this.userProjectMessage = this.userMessage;
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    watch: {
        // Watch for changes in isEmailScope to adjust character limits
        isEmailScope(newVal) {
            this.messageCharCountLimit = newVal ? 300 : 150;
            this.handleCharCount(); // Recalculate remaining characters
        },
        projectId: 'updateDemoMessageURL',
        projectSlug: 'updateDemoMessageURL',
        nameInURL: 'updateDemoMessageURL',
        template_type: 'updateDemoMessageURL',
    },
    methods: {
        // Method to generate the URL dynamically
        generateDemoMessageURL() {
            const baseUrl = 'https://invitatiismart.ro/#';
            if (this.template_type === 'simple') {
                // Simple project URL logic
                return `${baseUrl}/ps/${this.projectId}${
                    this.nameInURL == 1
                        ? '/' + encodeURIComponent(this.projectSlug)
                        : ''
                }`;
            } else {
                // Complex project URL logic
                return `${baseUrl}/public/${this.projectId}/invitationId${
                    this.nameInURL == 1
                        ? '/' + encodeURIComponent(this.projectSlug)
                        : ''
                }`;
            }
        },
        // Method to update the demoMessageURL when relevant props change
        updateDemoMessageURL() {
            this.demoMessageURL = this.generateDemoMessageURL();
        },
        async saveProjectMessage() {
            if (!this.userProjectMessage) {
                this.toast.info(`Mesajul e gol! Nu s-au efectual actualizari.`);
                return;
            }
            try {
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');

                const formData = {
                    projectMessage: this.userProjectMessage,
                    projectID: this.projectId,
                    scope: this.isEmailScope ? 'email' : 'sms',
                };

                await axios
                    .post(
                        '/api/index.php?endpoint=saveProjectMessage',
                        formData, // Pass form data in the request payload
                        {
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((result) => {
                        if (result.data.success) {
                            this.toast.success(
                                `Mesajul a fost salvat cu succes!`
                            );

                            if (this.isEmailScope) {
                                this.$store.commit(
                                    'setProjectInvitationMessageEmail',
                                    this.userProjectMessage
                                );
                            } else {
                                this.$store.commit(
                                    'setProjectInvitationMessage',
                                    this.userProjectMessage
                                );
                            }
                        } else {
                            this.toast.error(
                                `A aparut o eroare. ${result.data.message}`
                            );
                        }
                    })
                    .catch((error) => {
                        this.toast.error(`A aparut o eroare. ${error}`);
                    });
            } catch (error) {
                console.error(
                    'Error saving message:',
                    error.response ? error.response.data : error.message
                );
            }
        },
        handleCharCount() {
            this.messageCharCount =
                this.messageCharCountLimit -
                (this.userProjectMessage
                    ? this.userProjectMessage.length
                    : this.demoProjectMessage.length) -
                this.demoMessageURL.length;

            // Check if the character count exceeds the limit
            if (this.messageCharCount < 0) {
                // Trim the text to the maximum allowed characters
                this.userProjectMessage = this.userProjectMessage.substring(
                    0,
                    this.messageCharCountLimit - this.demoMessageURL.length
                );
            }
        },
    },
};
</script>
