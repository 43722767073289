<template>
    <div>
        <UserTopNav :active="'projects'" />
        <div class="max-w-screen-xl w-full mx-auto mb-10">
            <router-link
                :to="{
                    name: 'ProjectDetails',
                    params: {
                        projectId: currentProjectId,
                        projectName: currentProjectName,
                        projectSlug: projectSlug,
                    },
                }"
                ><button
                    class="my-10 font-bold bg-slate-500 hover:bg-slate-700 text-white shadow-xl transition px-6 py-2 rounded-full"
                >
                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                    Inapoi la Proiect
                </button></router-link
            >

            <div
                v-if="stats"
                class="border p-5 md:p-10 rounded-3xl bg-neutral-content"
            >
                <h1 class="text-4xl font-extralight">
                    {{ currentProjectName }}
                </h1>
                <h2 class="font-extrabold text-2xl mb-20">Statistici</h2>

                <div
                    v-if="scopedProjectData"
                    class="stats stats-vertical lg:stats-horizontal shadow"
                >
                    <div class="stat">
                        <div class="stat-figure text-secondary">
                            <font-awesome-icon
                                icon="fa-solid fa-calendar-check"
                                class="inline-block w-8 h-8 stroke-current"
                            />
                        </div>
                        <div class="stat-title">Data</div>
                        <div class="stat-value">
                            {{ formatDate(scopedProjectData.project_date) }}
                        </div>
                        <div class="stat-desc">Data evenimentului</div>
                    </div>

                    <div
                        v-if="scopedProjectData.template_type != 'simple'"
                        class="stat"
                    >
                        <div class="stat-figure text-secondary">
                            <font-awesome-icon
                                icon="fa-solid fa-address-book"
                                class="inline-block w-8 h-8 stroke-current"
                            />
                        </div>
                        <div class="stat-title">Invitați</div>
                        <div class="stat-value">
                            {{ scopedProjectData.participants.length }}
                        </div>
                        <div class="stat-desc">Invitații din lista ta</div>
                    </div>

                    <div class="stat">
                        <div class="stat-figure text-secondary">
                            <font-awesome-icon
                                class="inline-block w-8 h-8 stroke-current"
                                icon="fa-solid fa-eye"
                            />
                        </div>
                        <div class="stat-title">Vizualizari</div>
                        <div class="stat-value">
                            {{
                                stats.totalViews ||
                                scopedProjectData.public_views
                            }}
                        </div>
                        <div class="stat-desc">Numar total vizualizari</div>
                    </div>

                    <div class="stat">
                        <div class="stat-figure text-secondary">
                            <font-awesome-icon
                                icon="fa-solid fa-envelope-circle-check"
                                class="inline-block w-8 h-8 stroke-current"
                            />
                        </div>
                        <div class="stat-title">Total Raspunsuri</div>
                        <div class="stat-value">
                            {{ stats.totalAnswers }}
                        </div>
                        <div class="stat-desc">
                            <span class="badge badge-primary text-xs mr-2"
                                ><font-awesome-icon
                                    icon="fa-solid fa-user-check"
                                />
                                <span class="ml-1">{{
                                    stats.totalConfirmations
                                }}</span>
                            </span>

                            <span class="badge badge-accent text-xs"
                                ><font-awesome-icon
                                    icon="fa-solid fa-user-slash"
                                />
                                <span class="ml-1">{{
                                    stats.totalRefusals
                                }}</span></span
                            >
                        </div>
                    </div>
                </div>
                <div class="participantsList">
                    <GetParticipantsListStats
                        v-if="
                            scopedProjectData &&
                            scopedProjectData.template_type != 'simple'
                        "
                        :projectId="currentProjectId"
                    />

                    <GetParticipantsListStatsSimple
                        v-if="
                            scopedProjectData &&
                            scopedProjectData.template_type == 'simple'
                        "
                        :projectId="currentProjectId"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GetParticipantsListStats from '@/components/GetParticipantsListStats.vue';
import GetParticipantsListStatsSimple from '@/components/GetParticipantsListStatsSimple.vue';
import UserTopNav from '@/components/UserTopNav.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ProjectDetailsStats',
    props: {
        projectId: {},
        projectName: {
            type: String,
        },
        projectSlug: {
            type: String,
        },
    },
    components: {
        GetParticipantsListStats,
        GetParticipantsListStatsSimple,
        UserTopNav,
    },
    data() {
        return {
            currentProjectId: null,
            currentProjectName: null,
        };
    },
    created() {
        // Access the current route from the Vue Router
        const currentRoute = this.$route;

        // Access the project ID from the route parameters
        this.currentProjectId = currentRoute.params.projectId;
        this.currentProjectName = currentRoute.params.projectName;
    },
    mounted() {
        if (
            !this.scopedProjectData ||
            this.scopedProjectData.projectId != this.$route.params.projectId
        ) {
            const projectId = this.$route.params.projectId;
            const options = {};
            this.$store.dispatch('fetchProjectUsers', {
                projectId,
                options,
                participants: true,
            });
        }
    },
    computed: {
        ...mapGetters([
            'projects',
            'plan',
            'loading',
            'scopedProjectData',
            'stats',
        ]),
    },
    methods: {
        formatDate(dateString) {
            const months = [
                'Ian',
                'Feb',
                'Mar',
                'Apr',
                'Mai',
                'Iun',
                'Iul',
                'Aug',
                'Sep',
                'Oct',
                'Noi',
                'Dec',
            ];

            const [, monthIndex, day] = dateString.split('-');
            const month = months[parseInt(monthIndex, 10) - 1];

            return `${parseInt(day, 10)}${month}`;
        },
    },
};
</script>
