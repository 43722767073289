<template>
    <div class="md:max-w-screen-xl w-full mx-auto">
        <h2 class="my-10 text-2xl font-normal">Status invitați</h2>

        <table-lite
            v-if="scopedProjectData"
            :is-slot-mode="true"
            :is-static-mode="true"
            :is-loading="loading"
            :columns="columns"
            :rows="scopedProjectData.participants"
            :messages="messages"
            :total="scopedProjectData.participants.length"
            :sortable="sortable"
        >
            <template v-slot:attendance_status="data">
                <div v-if="data.value.response_adults" class="indicator">
                    <span
                        v-if="data.value.response_menu_type == 'vegetarian'"
                        class="indicator-item badge badge-primary shadow-md"
                    >
                        <font-awesome-icon icon="fa-solid fa-leaf" />
                    </span>

                    <div class="join">
                        <div
                            class="join-item badge badge-primary badge-lg"
                            v-if="data.value.attendance_status == 'Attending'"
                        >
                            <font-awesome-icon icon="fa-solid fa-user-check" />
                            <span class="pl-2">Participa</span>
                        </div>
                        <div
                            class="join-item badge bg-blue-500 text-white badge-lg"
                            v-if="data.value.attendance_status == 'Attending'"
                        >
                            <span
                                ><font-awesome-icon
                                    class=""
                                    icon="fa-solid fa-users"
                                />{{ data.value.response_adults }}</span
                            >
                        </div>
                        <div
                            class="join-item badge bg-blue-600 text-white badge-lg"
                            v-if="data.value.response_children"
                        >
                            <span v-if="data.value.response_children"
                                ><font-awesome-icon
                                    class=""
                                    icon="fa-solid fa-children"
                                />{{ data.value.response_children }}</span
                            >
                        </div>
                        <div
                            class="join-item badge bg-blue-700 text-white badge-lg"
                            v-if="data.value.response_accommodation"
                        >
                            <font-awesome-icon icon="fa-solid fa-bed" />
                        </div>
                    </div>
                </div>

                <div
                    class="badge badge-accent badge-lg"
                    v-if="data.value.attendance_status == 'Not Attending'"
                >
                    <font-awesome-icon icon="fa-solid fa-user-slash" />
                    <span class="pl-2">Nu Participa</span>
                </div>
            </template>

            <template v-slot:email_sent_time="data">
                <div class="grid grid-cols-3">
                    <div
                        class="tooltip cursor-default pl-1"
                        :data-tip="`Invitatie trimisa prin Email la ${data.value.email_sent_time}`"
                        v-if="data.value.email_sent_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-envelope-circle-check"
                            class="text-primary text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default"
                        :data-tip="`Invitatia nu a fost trimisa`"
                        v-else
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-envelope"
                            class="text-base-300 text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default pl-1"
                        :data-tip="`Invitatie trimisa prin SMS la ${data.value.sms_sent_time}`"
                        v-if="data.value.sms_sent_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-mobile-screen"
                            class="text-primary text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default"
                        :data-tip="`Invitatia nu a fost trimisa`"
                        v-else
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-mobile-screen"
                            class="text-base-300 text-2xl"
                        />
                    </div>

                    <div
                        class="tooltip cursor-default"
                        :data-tip="`Invitatie vizualizata la ${data.value.opened_time}`"
                        v-if="data.value.opened_time"
                    >
                        <font-awesome-icon
                            icon="fa-solid fa-eye"
                            class="text-primary text-2xl"
                        />
                    </div>
                    <div v-else>
                        <font-awesome-icon
                            icon="fa-solid fa-eye-slash"
                            class="text-base-300 text-2xl"
                        />
                    </div>
                </div>
            </template>
        </table-lite>
        <p
            v-if="scopedProjectData.participants.length === 0"
            class="text-gray-500 mt-4"
        >
            Nu s-a gasit nici un invitat pentru acest proiect.
        </p>

        <div v-if="loading">
            <LoadingComponent></LoadingComponent>
        </div>
    </div>
</template>

<script>
import TableLite from 'vue3-table-lite';
import LoadingComponent from './LoadingComponent.vue';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';

export default {
    props: ['projectId'],
    components: { TableLite, LoadingComponent },
    data() {
        return {
            isDeleteModalOpen: false,
            selectedDeleteParticipant: {},
            isEditModalOpen: false,
            editedParticipant: {},
            selectedProject: null,
            pageOptions: [
                { value: 100, text: 100 },
                { value: 150, text: 150 },
                { value: 200, text: 200 },
            ],
            columns: [
                {
                    label: 'Nume',
                    field: 'recipient_surname',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Prenume',
                    field: 'recipient_name',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Status Participare',
                    field: 'attendance_status',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Status Invitatie',
                    field: 'email_sent_time',
                    width: '5%',
                    sortable: false,
                },
                {
                    label: 'Mesaj',
                    field: 'response_message',
                    width: '15%',
                    sortable: true,
                },
                {
                    label: 'Vizualizari',
                    field: 'invitation_views',
                    width: '10%',
                    sortable: true,
                },
            ],
            sortable: {
                order: 'recipient_name',
                sort: 'desc',
            },
            messages: {
                pagingInfo:
                    'Se afiseaza {0} din {1} rezultate | Total {2} rezultate',
                pageSizeChangeLabel: 'Numar randuri afisate: ',
                gotoPageLabel: ' Mergi la pagina: ',
                noDataAvailable: 'Nu sunt disponibile informatii',
            },
        };
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    mounted() {},
    computed: {
        ...mapGetters([
            'projects',
            'plan',
            'loading',
            'scopedProjectData',
            'stats',
        ]),
    },
    methods: {},
};
</script>

<style>
/* Add any styling specific to this component */

.participantsList table {
    border-radius: 20px;
    border: 1px solid #cecece;
}
</style>
