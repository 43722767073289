<template>
    <div ref="container">
        <div
            class="floating-image absolute z-10"
            v-for="(image, index) in images"
            :key="index"
            :data-parallax="image.parallax"
            :data-float-speed="image.floatSpeed"
            :style="image.style"
        >
            <img :src="image.src" :alt="image.alt" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue';

export default {
    name: 'FloatingImages',
    props: {
        images: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    setup(props) {
        const container = ref(null);

        const animateFloatingImages = () => {
            const updateAnimation = () => {
                props.images.forEach((img) => {
                    const floatSpeed = img.floatSpeed || 2;
                    const floatOffset =
                        Math.sin((Date.now() / 1000) * floatSpeed) * 5;
                    img.style.transform = `translateY(${floatOffset}px)`;
                });

                requestAnimationFrame(updateAnimation);
            };

            updateAnimation();
        };

        const updateParallax = () => {
            props.images.forEach((img, index) => {
                nextTick(() => {
                    const parallaxIntensity = img.parallax || 0.5;
                    const element =
                        container.value.querySelectorAll('.floating-image')[
                            index
                        ];
                    const rect = element.getBoundingClientRect();
                    const scrollOffset =
                        window.pageYOffset ||
                        document.documentElement.scrollTop;
                    const parallaxOffset =
                        (scrollOffset - rect.top + window.innerHeight / 2) *
                        parallaxIntensity;
                    img.style.transform = `translateY(${parallaxOffset}px)`;
                });
            });
        };

        onMounted(() => {
            animateFloatingImages();
            window.addEventListener('scroll', updateParallax);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', updateParallax);
        });

        return {
            container,
        };
    },
};
</script>

<style>
.floating-image {
    transition: transform 0.1s linear;
}
.floating-image img {
    display: block;
    width: 100px; /* Adjust size as needed */
    height: auto;
}
</style>
