<template>
    <div class="md:hidden">
        <div
            id="burger"
            :class="{ active: isBurgerActive }"
            @click.prevent="toggle"
        >
            <button type="button" class="burger-button ml-5" title="Menu">
                <span class="hidden">Toggle menu</span>
                <span class="burger-bar burger-bar--1"></span>
                <span class="burger-bar burger-bar--2"></span>
                <span class="burger-bar burger-bar--3"></span>
            </button>
        </div>

        <Transition name="fade">
            <div
                class="sidebar"
                v-if="isBurgerActive"
                :class="{ active: isBurgerActive }"
            >
                <div class="sidebar-backdrop" @click.prevent="toggle"></div>

                <div
                    class="sidebar-panel bg-gradient-to-tr from-slate-900 to-slate-800 pt-10 text-xl"
                >
                    <ul class="sidebar-panel-nav" @click.prevent="toggle">
                        <li>
                            <RouterLink to="/">Acasă</RouterLink>
                        </li>
                        <li class="my-2">
                            <RouterLink to="/about">Despre noi</RouterLink>
                        </li>
                        <li class="my-2">
                            <RouterLink to="/howto"
                                >Cum funcționează</RouterLink
                            >
                        </li>
                        <li>
                            <RouterLink to="/pachete">Pachete</RouterLink>
                        </li>
                    </ul>

                    <ul class="sidebar-panel-nav mt-10" @click.prevent="toggle">
                        <li>
                            <RouterLink to="/userConfig">Contul meu</RouterLink>
                        </li>
                        <li>
                            <RegisterButton class="mt-2"></RegisterButton>

                            <LogoutButton :empty-button="true">
                                <div class="flex items-center mt-10 text-white">
                                    <font-awesome-icon
                                        icon="fa-solid fa-right-from-bracket"
                                        class="block md:hidden"
                                    />
                                    <div class="ml-2">Logout</div>
                                </div>
                            </LogoutButton>
                        </li>
                    </ul>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import RegisterButton from './RegisterButton.vue';
import LogoutButton from './LogoutButton.vue';

export default {
    data: () => ({
        isBurgerActive: false,
    }),
    components: { RouterLink, RegisterButton, LogoutButton },
    // computed: {
    //     isBurgerActive() {
    //         return store.isNavOpen;
    //     },
    // },
    methods: {
        toggle() {
            this.isBurgerActive = !this.isBurgerActive;
            this.updateBodyScroll();
        },
        updateBodyScroll() {
            if (this.isBurgerActive) {
                document.body.classList.add('overflow-hidden');
            } else {
                document.body.classList.remove('overflow-hidden');
            }
        },
    },
};
</script>
<style>
.burger-button {
    position: relative;
    height: 30px;
    width: 32px;
    display: block;
    z-index: 999;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar {
    background-color: #130f40;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
}

.burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(0.8);
}

.burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
}

.no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
}

.burger-bar--3 {
    transform: translateY(6px);
}

#burger.active .burger-button {
    transform: rotate(-180deg);
}

#burger.active .burger-bar {
    background-color: #fff;
}

#burger.active .burger-bar--1 {
    transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
    opacity: 0;
}

#burger.active .burger-bar--3 {
    transform: rotate(-45deg);
}

.sidebar-backdrop {
    background: rgba(0, 0, 0, 0.8);
    /* transition: all 0.5s; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -999;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
}

.sidebar-panel {
    position: fixed;
    height: 100vh;
    width: 80vw;
    left: -100vw;
    top: 0;
    transition: all 0.5s;
}

.active .sidebar-panel {
    left: 0;
}

/* Fade Transition for Backdrop */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter {
    opacity: 0;
}

.fade-leave-to {
    opacity: 0;
}

.sidebar-panel-nav {
    a {
        font-weight: bold;
        color: #d7d7d7;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
