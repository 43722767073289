<template>
    <div v-if="editMode">
        <ModalComponent :is-open="isModalOpen" @close="closeModal">
            <h3 class="font-bold text-lg">Adauga Elemente Secundare</h3>
            <button
                @click="closeModal"
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 btn-accent"
            >
                ✕
            </button>
            <div class="py-4">
                <div>
                    <button
                        class="btn btn-sm md:btn-md md:text-lg"
                        :class="section.maskedImages ? 'btn-accent' : ''"
                        @click="
                            section.maskedImages
                                ? deleteMaskedImages()
                                : addMaskedImages()
                        "
                    >
                        <font-awesome-icon icon="fa-solid fa-heart" />
                        <span v-if="section.maskedImages"
                            >Sterge imagini secundare</span
                        >
                        <span v-else>Adauga imagini secundare</span>
                    </button>

                    <button
                        class="btn btn-sm md:btn-md md:text-lg"
                        :class="section.showFloatingBaloons ? 'btn-accent' : ''"
                        @click="
                            section.showFloatingBaloons
                                ? deleteFloatingBaloons()
                                : addFloatingBaloons()
                        "
                    >
                        <font-awesome-icon icon="fa-solid fa-heart" />
                        <span v-if="section.showFloatingBaloons"
                            >Ascunde baloane</span
                        >
                        <span v-else>Adauga baloane</span>
                    </button>
                    <div v-if="section.maskedImages">
                        <!-- Display Masked Images -->
                        <div
                            class="flex w-full justify-center space-x-10 mt-10 drop-shadow-lg"
                        >
                            <img
                                class="mask mask-heart h-28 md:h-24 cursor-pointer"
                                :src="section.maskedImages.image1"
                                @click="editImage('image1')"
                            />
                            <img
                                class="mask mask-heart h-28 md:h-24 cursor-pointer"
                                :src="section.maskedImages.image2"
                                @click="editImage('image2')"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-action">
                <button class="btn btn-success" @click="closeModal">
                    Inchide
                </button>
            </div>
        </ModalComponent>

        <div class="tooltip tooltip-right" data-tip="Imagini Aditionale">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg"
                :class="
                    section.maskedImages || section.showFloatingBaloons
                        ? 'btn-accent'
                        : ''
                "
                @click="openModal"
            >
                <font-awesome-icon icon="fa-solid fa-heart" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    props: {
        section: {
            type: Object,
            required: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isEditing: false,
            editedValue: this.value,
            isModalOpen: false,
        };
    },
    components: {
        ModalComponent,
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    mounted() {
        this.newSection = this.section;
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
        },
        closeModal(option) {
            if (option === 'revert') {
                this.deleteMaskedImages();
            }
            this.isModalOpen = false;
        },
        editImage(imageKey) {
            this.$emit('edit-image', this.sectionKey, 'maskedImages', imageKey);
        },
        deleteMaskedImages() {
            this.$emit('delete-masked-images', this.sectionKey);
        },
        addMaskedImages() {
            this.$emit('update-section', this.sectionKey, {
                ...this.section,
                maskedImages: {
                    image1: './project-images/image-placeholder.jpg',
                    image2: './project-images/image-placeholder.jpg',
                },
            });
        },

        deleteFloatingBaloons() {
            this.newSection.showFloatingBaloons = false;
            this.$emit('update-section', this.sectionKey, this.newSection);
        },
        addFloatingBaloons() {
            this.newSection.showFloatingBaloons = true;
            this.$emit('update-section', this.sectionKey, this.newSection);
        },
    },
};
</script>

<style scoped></style>
