<!-- Modal.vue -->
<template>
    <div>
        <dialog :open="isOpen" :class="{ 'modal-open': isOpen }" class="modal">
            <div class="modal-box" :class="containerClass">
                <slot></slot>
            </div>
        </dialog>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        containerClass: {
            type: String,
            default: '',
        },
    },
};
</script>
