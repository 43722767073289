<template>
    <div>
        <!-- Button to open the modal -->
        <div>Trimite un SMS de test</div>
        <button
            @click="openSendSMSModal"
            class="btn btn-primary mt-2"
            :class="{ 'btn-disabled': isDisabled }"
        >
            <font-awesome-icon icon="fa-solid fa-comment-sms" class="" />
            Testeaza SMS
        </button>

        <!-- Modal -->
        <div v-if="isModalOpen" class="modal modal-open">
            <div class="modal-box">
                <h2 class="font-bold text-lg">Trimite un SMS de test</h2>
                <p class="py-4">
                    Această funcționalitate permite testarea trimiterii unui
                    SMS. <b>Creditele SMS vor fi utilizate pentru trimitere</b>.
                </p>

                <div class="form-control">
                    <label class="label">
                        <span class="label-text">Număr de telefon</span>
                    </label>
                    <input
                        type="text"
                        v-model="mobile"
                        placeholder="Introdu numărul de telefon"
                        class="input input-bordered"
                    />
                </div>

                <!-- Terms and Conditions Checkbox -->
                <div class="form-control mb-4 mt-2">
                    <label class="cursor-pointer flex items-center">
                        <input
                            type="checkbox"
                            class="checkbox checkbox-primary"
                            v-model="termsAccepted"
                        />
                        <span class="label-text ml-3 text-left">
                            Sunt de acord cu
                            <RouterLink
                                to="termsAndConditions"
                                class="link link-primary"
                                target="_blank"
                                >Termenii și Condițiile de
                                utilizare.</RouterLink
                            >
                        </span>
                    </label>
                </div>

                <div class="modal-action">
                    <button
                        @click="sendTestSMS"
                        class="btn btn-success"
                        :disabled="!termsAccepted"
                        :class="
                            loading
                                ? 'btn-disabled loading loading-spinner'
                                : ''
                        "
                    >
                        Trimite
                    </button>
                    <button
                        @click="closeSendSMSModal"
                        class="btn"
                        :class="loading ? 'btn-disabled' : ''"
                    >
                        Închide
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
    props: {
        userMobile: {
            type: String,
            required: true,
        },
        projectID: {
            type: Number,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            isModalOpen: false,
            mobile: this.userMobile,
            loading: false,
            termsAccepted: false,
        };
    },
    methods: {
        openSendSMSModal() {
            this.isModalOpen = true;
        },
        closeSendSMSModal() {
            this.isModalOpen = false;
        },
        validateMobile(mobile) {
            // Romanian mobile number regex: 10 digits, starts with '07'
            const romanianMobileRegex = /^07\d{8}$/;
            return romanianMobileRegex.test(mobile);
        },
        async sendTestSMS() {
            this.loading = true;

            if (!this.validateMobile(this.mobile)) {
                this.toast.error('Numărul de telefon nu este valid.');
                this.loading = false;
                return;
            }

            try {
                const jwtToken = localStorage.getItem('userToken');
                const response = await axios.post(
                    '/api/index.php?endpoint=sendSMSInvitation',
                    {
                        is_test_sms: true,
                        phone: this.mobile,
                        project_id: this.projectID,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );

                if (response.data.success) {
                    this.toast.success('SMS trimis cu succes!');
                    this.closeSendSMSModal();
                    this.loading = false;
                } else {
                    this.toast.error(
                        response.data.message ||
                            'Eroare la trimiterea SMS-ului.'
                    );
                    this.loading = false;
                }
            } catch (error) {
                this.toast.error(
                    'A apărut o eroare la comunicarea cu serverul.' + error
                );
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped></style>
