<template>
    <div class="footer border-t py-20">
        <div
            class="max-w-screen-xl w-full px-5 mx-auto grid grid-rows-4 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4"
        >
            <div
                class="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col md:items-start"
            >
                <img src="/images/logo.svg" class="w-60 mb-5 mx-auto" />
                <p class="mb-4">
                    Un serviciu inteligent pentru organizarea si monitorizarea
                    invitatiilor.
                </p>

                <p class="text-gray-400 mx-auto">© 2025 - InvitatiiSmart</p>
            </div>

            <div
                class="row-span-2 sm:col-span-2 sm:col-start-7 sm:col-end-9 flex flex-col"
            >
                <p class="text-black-600 mb-4 font-medium text-lg">Servicii</p>

                <ul class="text-black-500">
                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/pachete"
                            >Pachete și prețuri</RouterLink
                        >
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/howto">Cum funcționează</RouterLink>
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/cookiePolicy">FAQ</RouterLink>
                    </li>
                </ul>
            </div>

            <div
                class="row-span-2 sm:col-span-2 sm:col-start-9 sm:col-end-11 flex flex-col"
            >
                <p class="text-black-600 mb-4 font-medium text-lg">Companie</p>

                <ul class="text-black-500">
                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/about">Despre noi</RouterLink>
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/contact">Contact</RouterLink>
                    </li>
                </ul>
            </div>

            <div
                class="row-span-2 sm:col-span-2 sm:col-start-11 sm:col-end-13 flex flex-col"
            >
                <p class="text-black-600 mb-4 font-medium text-lg">Legal</p>

                <ul class="text-black-500">
                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/termsAndConditions"
                            >Termeni și condiții</RouterLink
                        >
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/privacyPolicy"
                            >Politica de Confidențialitate</RouterLink
                        >
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        <RouterLink to="/cookiePolicy"
                            >Politica de Cookies</RouterLink
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
