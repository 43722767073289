<template>
    <div>
        <UserTopNav :active="'projects'" />
        <div class="max-w-screen-xl w-full mx-auto mb-10">
            <router-link
                :to="{
                    name: 'ProjectDetails',
                    params: {
                        projectId: currentProjectId,
                        projectName: currentProjectName,
                        projectSlug: projectSlug,
                    },
                }"
                ><button
                    class="my-10 font-bold bg-slate-500 hover:bg-slate-700 text-white shadow-xl px-6 py-2 rounded-full"
                >
                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                    Inapoi la Proiect
                </button></router-link
            >

            <div class="border p-5 md:p-10 bg-neutral-content rounded-3xl">
                <h1 class="text-4xl font-extralight">
                    {{ currentProjectName }}
                </h1>
                <h2 class="font-extrabold text-2xl mb-20">Invitați</h2>

                <AddParticipantFormInline :projectId="currentProjectId" />
                <div class="participantsList">
                    <GetParticipantsList :projectId="currentProjectId" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddParticipantFormInline from '@/components/AddParticipantFormInline.vue';
import GetParticipantsList from '@/components/GetParticipantsList.vue';
import UserTopNav from '@/components/UserTopNav.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'ProjectDetailsInvitations',
    props: {
        projectId: {},
        projectName: {
            type: String,
        },
        projectSlug: {
            type: String,
        },
    },
    components: {
        AddParticipantFormInline,
        GetParticipantsList,
        UserTopNav,
    },
    data() {
        return {
            currentProjectId: null,
            currentProjectName: null,
        };
    },
    created() {
        // Access the current route from the Vue Router
        const currentRoute = this.$route;

        // Access the project ID from the route parameters
        this.currentProjectId = currentRoute.params.projectId;

        this.currentProjectName = currentRoute.params.projectName;
    },
    mounted() {
        if (
            !this.scopedProjectData ||
            this.scopedProjectData.projectId != this.$route.params.projectId
        ) {
            const projectId = this.$route.params.projectId;
            const options = {};
            this.$store.dispatch('fetchProjectUsers', {
                projectId,
                options,
                participants: true,
            });
        }
    },
    computed: {
        ...mapGetters([
            'projects',
            'plan',
            'loading',
            'scopedProjectData',
            'stats',
        ]),
    },
};
</script>
