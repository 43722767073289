<template>
    <div
        v-if="
            editMode &&
            section &&
            section._sectionType &&
            section._sectionType.value &&
            section._sectionType.value != 'form'
        "
        class="z-10"
    >
        <div class="tooltip tooltip-right" data-tip="Sterge Sectiune">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg btn-error"
                @click="confirmDelete"
            >
                <font-awesome-icon icon="fa-solid fa-trash-can" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        section: {
            type: Object,
            required: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isForm: false,
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        confirmDelete() {
            if (
                confirm(
                    'Confirma stergerea sectiunii | ' +
                        this.sectionKey +
                        ' | Aceasta actiune este ireversibila!'
                )
            ) {
                this.$emit('delete-section', this.sectionKey);
            }
        },
    },
};
</script>

<style scoped></style>
