<template>
    <div
        v-if="cookieAccepted !== 'accepted' && cookieAccepted !== 'declined'"
        class="bg-slate-200 fixed bottom-0 z-30 w-full py-5 px-3"
    >
        <div
            class="flex items-center justify-center flex-col gap-2 md:flex-row"
        >
            <p class="text-sm mb-5 md:mb-0">
                Acest site utilizează module cookie pentru a vă asigura cea mai
                bună experiență. Prin continuarea utilizării, sunteți de acord
                cu modulele cookie conform politicii noastre de
                confidențialitate.
            </p>
            <div>
                <button @click="acceptCookies" class="btn btn-secondary ml-5">
                    Înțeleg și accept
                </button>
                <button
                    @click="declineCookies"
                    class="btn btn-outline ml-5 mt-2 md:mt-0"
                >
                    Accept doar cele esențiale
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CookieNotification',
    data() {
        return {
            cookieAccepted: null, // Tracks user's cookie consent status
            isAuthenticated: false, // Check if the user is logged in
        };
    },
    created() {
        // Fetch consent preference from localStorage or database
        this.cookieAccepted = localStorage.getItem('userCookieAccept');
        this.isAuthenticated = !!localStorage.getItem('userToken'); // Example check for login
        if (this.cookieAccepted === 'accepted') {
            this.loadGoogleAnalytics();
        }
    },
    methods: {
        async acceptCookies() {
            localStorage.setItem('userCookieAccept', 'accepted');
            this.cookieAccepted = 'accepted';
            this.loadGoogleAnalytics();

            if (this.isAuthenticated) {
                await this.updateConsentInDatabase('accepted');
            }
        },
        async declineCookies() {
            localStorage.setItem('userCookieAccept', 'declined');
            this.cookieAccepted = 'declined';

            if (this.isAuthenticated) {
                await this.updateConsentInDatabase('declined');
            }
        },
        async updateConsentInDatabase(consentStatus) {
            try {
                const jwtToken = localStorage.getItem('userToken');
                await axios.post(
                    '/api/index.php?endpoint=updateConsent',
                    {
                        consent: consentStatus,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${jwtToken}`,
                        },
                    }
                );
                // console.log('Consent status updated successfully');
            } catch (error) {
                console.error(
                    'Failed to update consent in the database:',
                    error.response?.data || error.message
                );
            }
        },
        loadGoogleAnalytics() {
            // Dynamically load the Google Analytics script
            const existingScript = document.querySelector(
                `script[src="https://www.googletagmanager.com/gtag/js?id=G-4GE00HXF05"]`
            );
            if (!existingScript) {
                const script = document.createElement('script');
                script.src =
                    'https://www.googletagmanager.com/gtag/js?id=G-4GE00HXF05';
                script.async = true;
                document.head.appendChild(script);

                script.onload = () => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                        window.dataLayer.push(arguments);
                    }
                    window.gtag = gtag;

                    gtag('js', new Date());
                    gtag('config', 'G-4GE00HXF05', {
                        anonymize_ip: true, // Enable IP anonymization for GDPR compliance
                    });
                };
            }
        },
    },
};
</script>

<style scoped lang="scss">
/* Optional: Add styles for buttons or messages */
</style>
