<template>
    <button
        class="text-red-700 hover:text-red-500 transition"
        @click="deleteConfirmation()"
    >
        <font-awesome-icon :icon="['fas', 'trash-can']" /> Sterge
    </button>

    <dialog
        class="modal"
        :class="{
            'modal-open': showModal,
        }"
    >
        <div class="modal-box">
            <form method="dialog">
                <button
                    @click="showModal = false"
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                    ✕
                </button>
            </form>
            <h3 class="font-bold text-lg">Urmeaza sa stergi proiectul</h3>
            <p class="py-4">
                Daca proiectul este sters, vor fi sterse toate asocierile cu
                acesta si nu vor putea fi recuperate.
            </p>

            <button class="btn btn-error mr-5" @click="deleteProject()">
                Am inteles, sterge proiectul
            </button>
            <button class="btn btn-ghost" @click="showModal = false">
                Anuleaza
            </button>
        </div>
    </dialog>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import eventBus from '@/utils/EventBus';
import { handleDeauth } from '@/utils/auth';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            showModal: false,
        };
    },
    props: {
        projectId: {
            required: true,
        },
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    created() {
        // Check if the user is already authenticated (e.g., on page reload)
        this.isAuthenticated = this.checkAuthentication();
        // You may want to add a global event listener to listen for authentication changes
        window.addEventListener('storage', this.handleStorageEvent);
    },
    methods: {
        ...mapActions(['deleteProject']),
        async deleteProject() {
            try {
                await this.$store.dispatch('deleteProject', this.projectId);
                // Handle success (toast message, redirection, etc.)

                this.showModal = false;

                this.$router.push('/projects');
                this.toast.success(`Proiectul a fost șters cu succes!`);
            } catch (error) {
                this.toast.error(`A aparut o eroare.`);
                console.error('Error deleting project:', error);
            }
        },
        deleteConfirmation() {
            this.showModal = true;
        },
        async deleteProject_old() {
            try {
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');

                // Assuming you have form data available in Vue data properties
                const formData = {
                    projectId: this.projectId,
                };

                await axios
                    .post(
                        '/api/index.php?endpoint=deleteProject',
                        formData, // Pass form data in the request payload
                        {
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((result) => {
                        if (result.data.success) {
                            this.toast.success(
                                `Proiectul a fost sters cu succes! Vei fi redirectionat catre pagina principala.`
                            );

                            this.showModal = false;

                            eventBus.emit('ProjectDeleted', this.projectId);

                            setTimeout(() => {
                                this.$router.push('/projects');
                            }, 5000);
                        } else {
                            if (
                                result.data.message == 'Token validation error'
                            ) {
                                handleDeauth(this);
                            }
                            this.toast.error(`A aparut o eroare.`);
                        }
                    })
                    .catch(() => {
                        this.toast.error(`A aparut o eroare.`);
                    });
            } catch (error) {
                console.error(
                    'Error deleting project:',
                    error.response ? error.response.data : error.message
                );
            }
        },
        checkAuthentication() {
            // Check if the authentication token is present in localStorage
            const token = localStorage.getItem('userToken');
            return !!token; // Returns true if the token is present, otherwise false
        },
        handleStorageEvent(event) {
            // Listen for changes in localStorage (e.g., logout in another tab)
            if (event.key === 'userToken') {
                this.isAuthenticated = this.checkAuthentication();
            }
        },
    },
};
</script>
